<template>
  <div class="login-page auth-page__holder">

    <div class="login-page__logo">
      <a :href="clientLink" target="_blank">
        <img class="cd-logo" src="https://de-cyberdirekt-uploads-public.s3.eu-central-1.amazonaws.com/general/images/logo_white.svg"/>
      </a>
    </div>
    <div class="container login-container">
      <form action="#"
            novalidate
            @submit.prevent="setPassword"
            class="login-form">

        <h1 class="auth-page__title">{{ title }}</h1>
        <div class="auth-page__row mb-3"
             :class="{ 'has-error': $v.form.password.$error }">

          <div class="auth-page__row" :class="{ 'has-error': $v.form.password.$error }">
            <label class="input__holder form-group form-group--icon-l form-group--icon-r">
              <uiIcon name="lock" width="20" class="icon icon-l"></uiIcon>
              <b-form-input :type="!showPass ? 'password' : 'text' " id="password" class="form-trol" v-model="form.password" :placeholder="$t('common.password')" required></b-form-input>
              <uiIcon name="eye" width="17" class="icon icon-r" :class="{'icon-primary': showPass}" @click.native="() => {showPass = !showPass}"></uiIcon>
            </label>
          </div>
          <password-complexity :password="form.password"
                               @score="scoreChanged"/>
        </div>

        <div class="auth-page__row auth-page__repeat-pass-row" :class="{ 'has-error': $v.form.confirmPassword.$error }">
          <label class="input__holder form-group form-group--icon-l form-group--icon-r">
            <uiIcon name="lock" width="20" class="icon icon-l"></uiIcon>
            <b-form-input :type="!showPassRepeat ? 'password' : 'text' " id="confirm-password" class="form-trol" v-model="form.confirmPassword" :placeholder="$t('common.confirm_password')" required></b-form-input>
            <uiIcon name="eye" width="17" class="icon icon-r" :class="{'icon-primary': showPassRepeat}" @click.native="() => {showPassRepeat = !showPassRepeat}"></uiIcon>
          </label>
        </div>

        <div class="auth-page__buttons flex flex--v-center flex--between">
          <ButtonComponent
            btnStyle="accent"
            behavior="button"
            :class="{ 'btn--pending': passwordUpdatedPending }"
            type="submit"
            :disabled="$v.form.$error || passwordUpdatedPending">
            <icon name="sync"
                  class="btn__pending-icom"
                  color="white"
                  :rotate="passwordUpdatedPending"></icon>
            {{ $t('common.save') }}
          </ButtonComponent>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { MINIMUM_PASSWORD_LENGTH } from '@/constants';
  import { required, minLength, sameAs } from "vuelidate/lib/validators";
  import Icon from '@/components/icon/Icon.vue';
  import uiIcon from '@/components/ui/Icon';
  import Logo from '@/components/logo/Logo.vue';
  import PasswordComplexity from '@/components/password-complexity/PasswordComplexity.vue';
  import ButtonComponent from "@/components/ui/ButtonComponent.vue";

  export default {
    name: 'recovery-password',
    components: {
      Logo,
      Icon,
      uiIcon,
      PasswordComplexity,
      ButtonComponent
    },
    data() {
      return {
        form: {
          password: '',
          confirmPassword: '',
        },
        title: this.$t('page.recovery_password_title'),
        passwordUpdatedPending: false,
        passwordScore: null,
        showPass: false,
        showPassRepeat: false
      };
    },
    computed: {
      clientLink () {
        return process.env.CLIENT_BASE || process.env.VUE_APP_CLIENT_BASE
      }
    },
    metaInfo() {
      return {
        title: this.title,
      }
    },
    validations: {
      form: {
        password: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
        },
        confirmPassword: {
          required,
          minLength: minLength(MINIMUM_PASSWORD_LENGTH),
          sameAs: sameAs('password'),
        },
      }
    },
    methods: {
      async setPassword() {
        this.$v.form.$touch();

        if (this.$v.form.$error || this.passwordUpdatedPending) return;
        this.$v.form.$reset();
        this.passwordUpdatedPending = true;

        if (this.passwordScore < 2) {
          this.passwordUpdatedPending = false;
          return this.$toasted.show(this.$t('change_password.password_too_weak'), { type: 'error' });
        }

        const { success } = await this.$store.dispatch('RESET_PASSWORD', this.form.password);
        this.passwordUpdatedPending = false;

        if (success) {
          this.$router.push('/login');
        } else {
          this.$toasted.show('Something went wrong', { type: 'error' });
        }
      },
      scoreChanged(score) {
        this.passwordScore = score;
      },
    }
  }
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';
@import '@/styles/form.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.auth-page {
  &__holder {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-size: 1.25rem !important;
    font-weight: 800;
    margin-bottom: 2rem;
  }

  &__sub-title {
    text-align: center;
    font-weight: 300;
    padding: 0 0.5rem;
    margin-bottom: 2rem;
  }

  &__repeat-pass-row .input__holder {
    margin-bottom: 2rem;
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    width: 100%;

    .btn {
      width: 100%;
    }
  }
}

.cd-logo {
  margin-bottom: 3.5rem;
}

.login-page {
  background: #15192d;
  padding-top: 68px;

  &__logo {
    max-width: 420px;
    margin: 24px auto;
  }

  .login-container {
    max-width: 478px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
  }
}


.login-form {
  background: #fff;
  border-radius: 0.5rem;
  color: #454545;
  padding: 2.5rem 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media-breakpoint-down(xs) {
    padding: 2rem 1.5rem;
  }

  .login-top {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 2rem;

    ::v-deep button {
      svg {
        margin-left: -0.5rem;
        margin-right: 0.25rem;
      }
    }
  }

  .form-group {
    width: 100%;
    // margin-bottom: 2rem;

    &--icon-l {
      position: relative;

      .icon-l {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 14px;

        ::v-deep svg {
          color: $orangeTone;
        }
      }

      .form-control {
        padding-left: 2.75rem;
      }
    }

    &--icon-r {
      position: relative;

      .icon-r {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 14px;

        ::v-deep svg {
          color: #454545;
        }
      }

      .form-control {
        padding-right: 2.75rem;
      }
    }

    .icon-primary {
      ::v-deep svg {
        color: $primary;
      }
    }
  }

  .form-control {
    border: 1px solid #ced9dfe5;
    background: #CED9DF33;
    height: 40px;
    font-size:  0.9rem;
  }

  .input-group-text {
    background: #CED9DF33;
    border-right: none;
    padding-right: 0;
  }

  .input-group-prepend {
    margin-right: 0;

    & + .form-control {
      border-left: none;
    }
  }

  .submit-btn {
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    width: 100%;
  }

  &__bottom-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.has-error {
  .form-control {
    border: 2px solid $red;
  }
}

.b-footer-2 {
  margin-top: auto;
}
</style>

<style lang="scss">
  @import "@/styles/variables.scss";

  .change-password {
    &__complexity-holder {
      font-size: 0.875rem;
      line-height: 1.125rem;
    }

    &__complexity {
      font-weight: 600;

      &--red {
        color: $red;
      }

      &--orange {
        color: $orange;
      }

      &--green {
        color: $green;
      }
    }
  }
</style>
