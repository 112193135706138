<template>
  <div class="insurers-wrapper">
    <table >
      <thead>
        <tr>
          <th class="first-col-wrapper">
        <div class="first-col th-col">
        </div>
      </th>
      <th
      v-for="(insurer, iIndex) in innerInsurers"
      :key="`print-${iIndex}`"
      class="tb-cell insurer"
      :class="[
          {'insurer--disabled': insurer.disabled},
          `th-cell--${insurers.length}`,
          isRecommendationDeclinedMixin(insurer.key) && isOffer ? 'insurer--recommendation-declined' : ''
        ]"
      >
        <div
        class="insurer__image"
        :class="['insurer__image--' + getInsurerKey(insurer.key)]"
      >
      <div class="th-content">
        <img :src="getInsurerLogoPath(insurer)" :alt="insurer.label" />
      </div>
      </div>
      </th>
        </tr>

        <tr class="print-scores" :class="{'print-scores--price-perform-winner': isPricePerformWinner()}">
        <th class="first-col-wrapper">
          <div class="first-col th-col">
            <b class="mr-2">Jahresprämie</b> <span class="text-sm">(inkl. {{insTaxRate}}% VSt.)</span>
          </div>
        </th>
        <th
          v-for="insurer in insurers"
          :key="insurer.key"
          class="price th-col"
          :class="[
            {
              'price--disabled': insurer.disabled,
              'price-perform-winner': isInsurerPricePerformWinner(insurer.key)
            },
            `th-col--${insurers.length}`
          ]">
        <div
          class="th-col-content"
          v-tooltip.bottom="getPriceToolTip(insurer)"
        >
          <span
            :style="[isRecommendationDeclinedMixin(insurer.key) ? {
              color: 'transparent'
            } : '']"
          >
            {{ $store.state.offer?.offer?.priceOverwrite?.[insurer.insurerKey]?.gross || insurer.prices?.gross || '' | currency(2) }}
            <span v-if="getPriceToolTip(insurer)">*</span>
          </span>
        </div>
        </th>
        </tr>
        <tr class="price-spacer"></tr>
      </thead>
    </table>
  </div>
</template>

<script>
import { get as getValue } from 'lodash'
import agencyKey from '../../../mixins/agencyKey'
import tariffsWithScanMixin from '../../../mixins/tariffsWithScanMixin.js'

export default {
  props: {
    insurers: {
      type: Array,
      default() {
        return []
      }
    },
    synaxon: Object,
    checkoutButtonIsVisible: Boolean,
    affiliateBroker: Object,
    getBanner: Function,
    getBannerClass: Function,
    getPriceToolTip: Function,
    isInsurerPricePerformWinner: Function,
    isPricePerformWinner: Function,
    order1: String,
    order2: String,
    isBroker: Boolean,
    isOffer: Boolean,
    mode: String,
    offerSelection: Array,
    maxOffers: Number
  },
  data() {
    return {
      innerInsurers: this.insurers,
      offerSelectionInner: this.offerSelection
    }
  },
  watch: {
    offerSelectionInner(newValue) {
      this.$emit('updateOfferSelection', newValue)
    },
    insurers: {
      handler (newvalue) {
        this.innerInsurers = newvalue
      },
      deep: true
    },
  },
  mounted() {
  },
  methods: {
    getInsurerKey(key) {
      return key.indexOf('victor') > -1 ? 'victor' : key
    },

    shouldShowArtusBanner(insurer) {
      const isArtus =
        this.$store.state.user &&
        this.$store.state.user.brokerAgency &&
        this.$store.state.user.brokerAgency.key &&
        this.$store.state.user.brokerAgency.key.includes('artus')
      const offerKey = getValue(this.$store.state, 'offer.offer.key')
      const isArtusCampaign = offerKey && offerKey.includes('artus')

      return (
        (isArtus || isArtusCampaign) &&
        ['aig', 'hiscox'].indexOf(insurer.key) > -1
      )
    },

    getInsurerLogoPath(insurer) {
      return insurer.logoUrl
    },

    getLabelStyle(data) {
      if (
        data != null &&
        this.affiliateBroker &&
        this.affiliateBroker.agency == data
      ) {
        return `insurer__label--${data}`
      }
    },

    selectInsurer(insurer) {
      this.$emit('selectInsurer', insurer)
    },

    forceUpdateInsurers () {
      this.innerInsurers = this.insurers
    }
  },
  mixins: [agencyKey, tariffsWithScanMixin]
}
</script>

<style lang="scss" scoped>
@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../scss/tooltip';
@import '../../../scss/form';
@import '../../../scss/button';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../../scss/print/insurersPrint.scss';

.insurers-wrapper {
  // width: calc(100% + 0.5cm);
  padding-right: 2px;

  table {
    border-spacing: 2px 0;
  }
}

table {
  width: 100%;
  border-collapse: separate;
}

.first-col-wrapper {
  min-width: 350px;
  max-width: 350px;
  width: 350px;
  // width: 30%;
}

.tb-cell {
  // width: 17.5%;
  min-width: 155px;
  max-width: 155px;
}

.tb-cell:not(:last-child) {
  padding: 2px 6px 0 0;
}

//

.insurers {
  display: flex;
  gap: 1rem;

  @include media-breakpoint-down(sm) {
    gap: 0.5rem;
  }
}

.insurers-space {
  @include media-breakpoint-down(sm) {
    margin-left: 298px;
  }
}

.company {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 3rem;
  // gap: 1rem;
  width: 290px;

  @include media-breakpoint-down(sm) {
    padding: 1rem;
  }

  &--offer {
    justify-content: center;

    .insurer__image {
      margin-bottom: 0;
    }
  }
}

.logo img {
  // min-height: 65px;
  max-width: 120px;
}

.badge {
  margin-bottom: 10px;
}

.insurer {
  position: relative;

  &__label--vodafone {
    background: #e60000 !important;
  }

  &__head {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__banner {
    padding: 2px;
    width: 100%;
    background: $primary;
    color: #fff;
    font-weight: bold;
    font-size: 0.8rem;
    text-align: center;

    &--warning {
      background: $redTone;
    }

    &--green {
      background: $greenTone;
    }

    &--artus {
      background: #ffb200;
      position: absolute;
      top: -20px;
    }

    &--synaxon {
      background: $secondary;
    }
  }

  &__image {
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: auto;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1.5rem;
    }

    img {
      margin: 0 auto;
      display: block;
      // width: 100%;
      width: auto;
      max-width: 85px;
      max-height: 100%;

      @include media-breakpoint-up(md) {
        max-width: 100px;
        max-height: 65px;
      }
    }

    &--aig {
      img {
        width: 100px;
      }
    }

    &--markel {
      img {
        max-height: 60px;
        width: auto;
      }
    }

    &--hdi {
      img {
        height: 40px;
      }
    }

    &--hdi-vodafone {
      img {
        height: 40px;
      }
    }

    &--axa {
      img {
        height: 40px;
      }
    }

    &--allianz {
      img {
        min-width: 120px;
      }
    }

    &--victor {
      img {
        height: 60px;
      }
    }

    &--corvus-rc1-sideletter,
    &--corvus-rc2-sideletter,
    &--corvus-rc3-sideletter,
    &--corvus-rc4-sideletter,
    &--corvus-rc5-sideletter,
    &--corvus-rc1-standard,
    &--corvus-rc2-standard,
    &--corvus-rc3-standard,
    &--corvus-rc4-standard,
    &--corvus-rc5-standard  {
      img {
        height: 50px;
        max-width: 200px;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__badge {
    position: absolute;
    bottom: 2rem;

    @include media-breakpoint-down(sm) {
      bottom: 18px;
    }
  }

  &__select {
    height: 50px;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;

    &__top {
      height: 50%;
    }

    &__bottom {
      height: 50%;
    }
  }

  &__offer-check {
    height: 24px;
  }

  .custom-checkbox {
    margin-bottom: 4rem;

    @include media-breakpoint-down(sm) {
      margin-bottom: 1rem;
    }
  }

  &--disabled {

    & > *:not(.insurer__bottom) {
      opacity: 0.5;
      filter: grayscale(100%);
    }

    .insurer__badge {
      position: static;
      background: $redTone;
    }

    .action {
      opacity: 0.5;
      filter: grayscale(100%);
    }
  }
}

.print-scores {
  // width: 100vh;
  width: 99.3%;
  margin-bottom: 0;

  &--price-perform-winner {
    .th-col-content, .first-col {
      margin-top: 8px;
    }
  }

  tr {
    // width: 100%;
    width: 100vh;
  }
  & th {
    &:not(:last-child) {
      padding: 2px 4px 0 0;
    }

    &.price {
      min-width: 155px;
      max-width: 155px;

      &--disabled {
        filter: grayscale(100%);
        opacity: 0.5;
      }
    }

    &.price.th-col.th-col--3 {
      // width: 17.5%;
    }
  }

  .first-col-td {
    padding-bottom: 4px;
    // width: 100%;
  }

  .first-col-wrapper {
    width: 355px;
    max-width: 355px;
    min-width: 355px;
    // width: 30%;
    // width: 280px;
    margin-right: 4px;

  }

  .first-col.td-col {
    margin-right: 4px;
  }

  .td {
    &--1, &--2, &--3, &--4 {
      width: 17.5%;
    }
  }

  .td-col {
    &__content {
      padding: 32px 10px;
      background: #ebf0f2;
      border-radius: 0.25rem;
    }

    &--disabled {
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }

  .td-col-content {
    display: flex;
    justify-content: center;
    // width: 17.5%;
    // min-width: 155px;
  }

  .th-col:not(:last-child) {
    padding: 2px 4px 0 0;
  }
  .th-col:last-child {
    padding: 2px 0 0 0;
  }

  .th-col--1:not(:last-child) {
    width: 17.5%;
  }

  .th-col-content {
    padding: 0.5rem 0.25rem;
    text-align: center;
    border-radius: 0.25rem;
    color: #fff;
    background: #12658b;

  }
  .first-col.th-col {
    padding: 0.5rem 10px;
    font-weight: normal;
    background: #12658b;
    color: #fff;
    border-radius: 0.25rem;

  }
}
.price-perform-winner {
  position: relative;

  &::before {
    content: 'Preis-Leistungs-Sieger';
    font-weight: normal;
    color: #000;
    text-align: center;
    font-size: 14px;
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
  }

  .th-col-content {
    background: #417E9B;
  }
}

.price-spacer {
  height: 6px;
}
</style>
